export const m = {
    // 日语语言包
    Login: {
        "WeShoWPlatformManagement": "WeShoWプラットフォーム管理",
        "WeshoWManagement": "WeshoW管理",
        "Username": "ユーザー名",
        "Password": "パスワード",
        "GoToWeShoWManagement": "WeshoW管理へ",
        "Error": "エラー",
        "LoginFailed": "ログイン失敗しました",
        "Login": "ログイン",
        "AuthenticationFailed": "認証失敗しました",
        "PleaseLoginAgain": "もう一度ログインしてください"
    },
    EventList: {
        // 開催日時
        "EventStartDate": "開催日時",
        // 終了日時
        "EventEndDate": "終了日時",
        // 間隔(秒)
        "Interval": "間隔(秒)",
        // 秒
        "Second": "秒",
        // 参加者間のインターバル
        "IntervalBetweenParticipants": "参加者間のインターバル",
        // イベントタイプ表示
        "EventTypeDisplay": "イベントタイプ表示",
        // 用例
        "Example": "用例",
        // 注意事項
        "Notes": "注意事項",
        // 参加証
        "Certificate": "参加証",
        // 記述
        "Description": "記述",
        // 録画
        "Recording": "録画",
        // 録画する
        "Record": "録画する",
        // 録画しない
        "NotRecord": "録画しない",
        // ギフト機能
        "GiftFunction": "ギフト機能",
        // 利用する
        "Use": "利用する",
        // 利用しない
        "NotUse": "利用しない",
        // ギフト
        "Gift": "ギフト",
        // 全部
        "All": "全部",
        // シーン
        "Scene": "シーン",
        // 撮影準備時間(秒)
        "ShootingPreparationTime": "撮影準備時間(秒)",
        // 終了挨拶時間(秒)
        "ClosingGreetingTime": "終了挨拶時間(秒)",
        // 有効日数(日)
        "ValidDays": "有効日数(日)",
        // 日
        "Day": "日",
        // デフォルト
        "Default": "デフォルト",
        // サイン時間(秒)
        "SignTime": "サイン時間(秒)",
        // 写真種類
        "PhotoType": "写真種類",
        // フレーム
        "Frame": "フレーム",
        // 写真画像
        "PhotoImage": "写真画像",
        // チャートルーム機能
        "ChatRoomFunction": "チャートルーム機能",
        // チャートルーム背景
        "ChatRoomBackground": "チャートルーム背景",
        // 字幕翻訳機能
        "CaptionTranslationFunction": "字幕翻訳機能",
        // アーティスト言語
        "ArtistLanguage": "アーティスト言語",
        // バーチャルモデル機能
        "VirtualModelFunction": "バーチャルモデル機能",
        // バーチャルモデル
        "VirtualModel": "バーチャルモデル",
        // バーチャルモデルを選択
        "SelectVirtualModel": "バーチャルモデルを選択",
        // カバー画像
        "CoverImage": "カバー画像",
        // モニタリング画面のカバー画像
        "CoverImageOfMonitoringScreen": "モニタリング画面のカバー画像",
        // ライブ配信者のアイコン
        "IconOfLiveDistributor": "ライブ配信者のアイコン",
        // 背景画像
        "BackgroundImage": "背景画像",
        // 配信前の背景画像
        "BackgroundImageBeforeDelivery": "配信前の背景画像",
        // 配信中の背景画像
        "BackgroundImageDuringDelivery": "配信中の背景画像",
        // バーチャルモデルの背景画像
        "BackgroundImageOfVirtualModel": "バーチャルモデルの背景画像",
        // 販売元
        "Seller": "販売元",
        // 認証コード発行
        "IssueAuthCode": "認証コード発行",
        // ファン管理機能より認証コードを発行する。
        "IssueAuthCodeFromFanManagementFunction": "ファン管理機能より認証コードを発行する。",
        // Weshow販売
        "WeshowSales": "Weshow販売",
        // WeShoWチケット販売ページでチケットを購入後に認証コードを発行する。
        "IssueAuthCodeAfterPurchasingTicket": "WeShoWチケット販売ページでチケットを購入後に認証コードを発行する。",
        // LivePocket販売
        "LivePocketSales": "LivePocket販売",
        // LivePocketのチケット仕様に合わせた認証コード付きのcsvファイルが作成される。
        "CsvFileWithAuthCodeIsCreatedAccordingToTicketSpecificationsOfLivePocket": "LivePocketのチケット仕様に合わせた認証コード付きのcsvファイルが作成される。",
        // 価格
        "Price": "価格",
        // WeShoWチケット販売で表示される注意事項の設定。
        "SettingOfNotesDisplayedInWeShoWTicketSales": "WeShoWチケット販売で表示される注意事項の設定。",
        // 販売開始日時
        "SalesStartDateAndTime": "販売開始日時",
        // 販売終了日時
        "SalesEndDateAndTime": "販売終了日時",
        // 最大枚数 / 1注文
        "MaximumNumberOfSheetsPerOrder": "最大枚数 / 1注文",
        // 販売チケット枚数
        "NumberOfTicketsSold": "販売チケット枚数",
        // 1枚当たりのチケット秒数
        "TicketSecondsPerSheet": "1枚当たりのチケット秒数",
        // チケット利用区分
        "TicketUsageClassification": "チケット利用区分",
        // イベントタイプ
        "EventType": "イベントタイプ",
        // 基本情報
        "BasicInformation": "基本情報",
        // イベント設定
        "EventSetting": "イベント設定",
        // なし
        "None": "なし",
        // バーチャルモデルプレビュー
        "VirtualModelPreview": "バーチャルモデルプレビュー",
        // 販売情報
        "SalesInformation": "販売情報",
        // 円
        "Yen": "円",
        // 枚
        "Sheets": "枚",
        // 戻る
        "Back": "戻る",
        // 次へ
        "Next": "次へ",
        // 確認
        "Confirm": "確認",
        // アドレス
        "Address": "アドレス",
        // 一般配信用URL
        "GeneralDeliveryUrl": "一般配信用URL",
        // OBS配信用ドメイン
        "OBSDeliveryDomain": "OBS配信用ドメイン",
        // OBS配信用キー
        "OBSDeliveryKey": "OBS配信用キー",
        // 販売リンク
        "SalesLink": "販売リンク",
        // 詳細
        "Details": "詳細",
        // 握手会
        "HandshakeMeeting": "握手会",
        // 撮影会
        "PhotoSession": "撮影会",
        // リアルタイム写真
        "RealTimePhoto": "リアルタイム写真",
        // 事前準備の写真
        "PhotoOfPreparation": "事前準備の写真",
        // 両方選択
        "BothSelected": "両方選択",
        // 成功
        "Success": "成功",
        // 成功しました！
        "Successed": "成功しました！",
        // エラー
        "Error": "エラー",
        // 予期できないエラーが発生しました。
        "UnexpectedErrorOccurred": "予期できないエラーが発生しました。",
        // 本当に削除しますか？
        "DoYouReallyWantToDelete": "本当に削除しますか？",
        // アラート
        "Alert": "アラート",
        // はい
        "Yes": "はい",
        // いいえ
        "No": "いいえ",
        // 開催前
        "BeforeTheEvent": "開催前",
        // 開催中
        "DuringTheEvent": "開催中",
        // 開催終了
        "AfterTheEvent": "開催終了",
        // コピーしました
        "Copied": "コピーしました",
        // コピー失敗しました
        "CopyFailed": "コピー失敗しました",
        // シリアルまとめ売り
        "SerialSummarySales": "シリアルまとめ売り",
        // シリアルばら売り
        "SerialBulkSales": "シリアルばら売り",
        // 削除
        "Delete": "削除",
        // ファン管理
        "FanManagement": "ファン管理",
        // アンケート参照
        "QuestionnaireReference": "アンケート参照",
        // 録画管理
        "RecordingManagement": "録画管理",
        // スマホで配信する
        "DeliverOnSmartphone": "スマホで配信する",
        "EventManagement": "イベント管理",
        "ArtistName": "アーティスト名",
        "EventName": "イベント名",
        "StartTime": "開始時間",
        "EndTime": "終了時間",
        "AuthenticationCode": "認証コード",
        "Search": "検索",
        "Add": "追加",
        "EventStatus": "イベント状況",
        "Title": "タイトル",
        "Organization": "組織",
        "EventType": "イベント種類",
        "1on1": "1on1",
        "Virtual": "バーチャル",
        "TwoShot": "ツーショット",
        "LiveStreaming": "ライブ配信",
        "Standard": "標準",
        "LowLatency": "低遅延",
        "Sign": "サイン会",
        "OrderType": "並び順種類",
        "SalesOrder": "売り順",
        "AdmissionOrder": "入場順",
        "Artist": "アーティスト",
        "EventTime": "イベント時間",
        "TicketQuantity": "発行数",
        "SalesDestination": "販売先",
        "Actions": "操作",
        "Edit": "編集",
        "Total": "合計",
        "SelectEventType": "イベントタイプを選んでください",
        "SortByEntryTime": "入場した人から時間順に並びます。",
        "ArrangedBySpecifiedTime": "事前に指定した時間順に並ばせます。"
    },
    utils: {
        // {label}は必要項目です
        "Required": "{label}は必要項目です",
        // {label}が一致しません
        "NotMatch": "{label}が一致しません",
        // {label}の日時が間違っています
        "InvalidDateTime": "{label}の日時が間違っています",
        // {label}は{min}文字以上です
        "MinLength": "{label}は{min}文字以上です",
        // {label}は${min}文字以内です
        "MaxLength": "{label}は{max}文字以内です",
    },
    Cropper: {
        // 画像選択
        "ImageSelection": "画像選択",
        // 画像編集
        "ImageEditing": "画像編集",
        // キャンセル
        "Cancel": "キャンセル",
        // 確認
        "Confirm": "確認",
    },
    ArtistsList: {
        // アーティスト管理
        "ArtistManagement": "アーティスト管理",
        // 組織名
        "OrganizationName": "組織名",
        // 芸名
        "StageName": "芸名",
        // 検索
        "Search": "検索",
        // 追加
        "Add": "追加",
        // アバター
        "Avatar": "アバター",
        // アカウント
        "Account": "アカウント",
        // 操作
        "Actions": "操作",
        // 編集
        "Edit": "編集",
        // 削除
        "Delete": "削除",
        // 合計
        "Total": "合計",
        // 記述
        "Description": "記述",
        // パスワード
        "Password": "パスワード",
        // 再確認
        "Reconfirm": "再確認",
        // キャンセル
        "Cancel": "キャンセル",
        // 確認
        "Confirm": "確認",
        // 組織
        "Organization": "組織",
        // パスワードは5文字以上
        "PasswordIsAtLeast5Characters": "パスワードは5文字以上",
        // 成功
        "Success": "成功",
        // 成功しました！
        "Successed": "成功しました！",
        // エラー
        "Error": "エラー",
        // 予期できないエラーが発生しました。
        "UnexpectedErrorOccurred": "予期できないエラーが発生しました。",
        // 本当に削除しますか？
        "DoYouReallyWantToDelete": "本当に削除しますか？",
        // アラート
        "Alert": "アラート",
        // はい
        "Yes": "はい",
        // いいえ
        "No": "いいえ",
    },
    BanList: {
        // ブラックリスト
        "Blacklist": "ブラックリスト",
        // 開始日付
        "StartDate": "開始日付",
        // コード/メールの検索
        "SearchCodeEmail": "コード/メールの検索",
        // 検索
        "Search": "検索",
        // 時間
        "Time": "時間",
        // BANの時間
        "BanTime": "BANの時間",
        // 開始時間
        "StartTime": "開始時間",
        // 終了時間
        "EndTime": "終了時間",
        // イベント
        "Event": "イベント",
        // ニックネーム
        "Nickname": "ニックネーム",
        // 認証コード
        "AuthenticationCode": "認証コード",
        // メール
        "Email": "メール",
        // はい
        "Yes": "はい",
        // いいえ
        "No": "いいえ",
        // BANされた原因
        "BanReason": "BANされた原因",
        // 合計
        "Total": "合計",
    },
    FanList: {
        // イベント管理
        "EventManagement": "イベント管理",
        // {eventTitle}のファン管理
        "FanManagementOfEventTitle": "{eventTitle}のファン管理",
        // ネーム/コード/メール
        "NameCodeEmail": "ネーム/コード/メール",
        // 検索
        "Search": "検索",
        // ポイントインポート
        "PointImport": "ポイントインポート",
        // ポイントエクスポート
        "PointExport": "ポイントエクスポート",
        // エクスポート
        "Export": "エクスポート",
        // 一括追加
        "BulkAddition": "一括追加",
        // アバター
        "Avatar": "アバター",
        // メール
        "Email": "メール",
        // はい
        "Yes": "はい",
        // いいえ
        "No": "いいえ",
        // 認証コード
        "AuthenticationCode": "認証コード",
        // 時間帯
        "Timezone": "時間帯",
        // 時間
        "Time": "時間",
        // 秒
        "Seconds": "秒",
        // ポイント
        "Point": "ポイント",
        // 操作
        "Actions": "操作",
        // 編集
        "Edit": "編集",
        // 追加
        "Add": "追加",
        // 合計
        "Total": "合計",
        // ニックネーム
        "Nickname": "ニックネーム",
        // メールアドレス
        "EmailAddress": "メールアドレス",
        // キャンセル
        "Cancel": "キャンセル",
        // 確認
        "Confirm": "確認",
        // 一括追加
        "BulkAddition": "一括追加",
        // 作成数
        "CreationNumber": "作成数",
        // 撮った写真
        "TakenPhotos": "撮った写真",
        // ツーショット
        "TwoShot": "ツーショット",
        // エラー
        "Error": "エラー",
        // 予期できないエラーが発生しました。
        "UnexpectedErrorOccurred": "予期できないエラーが発生しました。",
        // 権利枚数
        "NumberOfRights": "権利枚数",
        // 成功
        "Success": "成功",
        // 成功しました！
        "Successed": "成功しました！",
        // 本当にBANしますか？
        "DoYouReallyWantToBan": "本当にBANしますか？",
        // アラート
        "Alert": "アラート",
        // コピーしました
        "Copied": "コピーしました",
        // コピー失敗しました
        "CopyFailed": "コピー失敗しました",
        // 写真
        "Photo": "写真",
        // 振替
        "Transfer": "振替",
        // サイン
        "Sign": "サイン",
    }
};