export const m = {
    // 英语语言包
    Login: {
        "WeShoWPlatformManagement": "WeShoW Platform Management",
        "WeshoWManagement": "WeShoW Management",
        "Username": "User name",
        "Password": "Password",
        "GoToWeShoWManagement": "Go to WeShoW Management",
        "Error": "Error",
        "LoginFailed": "Login failed",
        "Login": "Login",
        "AuthenticationFailed": "Authentication failed",
        "PleaseLoginAgain": "Please login again"
    },
    EventList: {
        // 開催日時
        "EventStartDate": "Event Start Date",
        // 終了日時
        "EventEndDate": "Event End Date",
        // 間隔
        "Interval": "Interval",
        // 秒
        "Second": "Second",
        // 参加者間のインターバル
        "IntervalBetweenParticipants": "Interval Between Participants",
        // イベントタイプ表示
        "EventTypeDisplay": "Event Type Display",
        // 用例
        "Example": "Example",
        // 注意事項
        "Notes": "Notes",
        // 参加証
        "Certificate": "Certificate",
        // 記述
        "Description": "Description",
        // 録画
        "Recording": "Recording",
        // 録画する
        "Record": "Record",
        // 録画しない
        "NotRecord": "Not Record",
        // ギフト機能
        "GiftFunction": "Gift Function",
        // 利用する
        "Use": "Use",
        // 利用しない
        "NotUse": "Not Use",
        // ギフト
        "Gift": "Gift",
        // 全部
        "All": "All",
        // シーン
        "Scene": "Scene",
        // 撮影準備時間(秒)
        "ShootingPreparationTime": "Shooting Preparation Time(Second)",
        // 終了挨拶時間(秒)
        "ClosingGreetingTime": "Closing Greeting Time(Second)",
        // 有効日数(日)
        "ValidDays": "Valid Days(Day)",
        // 日
        "Day": "Day",
        // デフォルト
        "Default": "Default",
        // サイン時間(秒)
        "SignTime": "Sign Time(Second)",
        // 写真種類
        "PhotoType": "Photo Type",
        // フレーム
        "Frame": "Frame",
        // 写真画像
        "PhotoImage": "Photo Image",
        // チャートルーム機能
        "ChatRoomFunction": "Chat Room Function",
        // チャートルーム背景
        "ChatRoomBackground": "Chat Room Background",
        // 字幕翻訳機能
        "CaptionTranslationFunction": "Caption Translation Function",
        // アーティスト言語
        "ArtistLanguage": "Artist Language",
        // バーチャルモデル機能
        "VirtualModelFunction": "Virtual Model Function",
        // バーチャルモデル
        "VirtualModel": "Virtual Model",
        // バーチャルモデルを選択
        "SelectVirtualModel": "Select Virtual Model",
        // カバー画像
        "CoverImage": "Cover Image",
        // モニタリング画面のカバー画像
        "CoverImageOfMonitoringScreen": "Cover Image Of Monitoring Screen",
        // ライブ配信者のアイコン
        "IconOfLiveDistributor": "Icon Of Live Distributor",
        // 背景画像
        "BackgroundImage": "Background Image",
        // 配信前の背景画像
        "BackgroundImageBeforeDelivery": "Background Image Before Delivery",
        // 配信中の背景画像
        "BackgroundImageDuringDelivery": "Background Image During Delivery",
        // バーチャルモデルの背景画像
        "BackgroundImageOfVirtualModel": "Background Image Of Virtual Model",
        // 販売元
        "Seller": "Seller",
        // 認証コード発行
        "IssueAuthCode": "Issue Auth Code",
        // ファン管理機能より認証コードを発行する。
        "IssueAuthCodeFromFanManagementFunction": "Issue Auth Code From Fan Management Function.",
        // Weshow販売
        "WeshowSales": "Weshow Sales",
        // WeShoWチケット販売ページでチケットを購入後に認証コードを発行する。
        "IssueAuthCodeAfterPurchasingTicket": "Issue Auth Code After Purchasing Ticket From WeShoW Ticket Sales Page.",
        // LivePocket販売
        "LivePocketSales": "LivePocket Sales",
        // LivePocketのチケット仕様に合わせた認証コード付きのcsvファイルが作成される。
        "CsvFileWithAuthCodeIsCreatedAccordingToTicketSpecificationsOfLivePocket": "Csv File With Auth Code Is Created According To Ticket Specifications Of LivePocket.",
        // 価格
        "Price": "Price",
        // WeShoWチケット販売で表示される注意事項の設定。
        "SettingOfNotesDisplayedInWeShoWTicketSales": "Setting Of Notes Displayed In WeShoW Ticket Sales.",
        // 販売開始日時
        "SalesStartDateAndTime": "Sales Start Date And Time",
        // 販売終了日時
        "SalesEndDateAndTime": "Sales End Date And Time",
        // 最大枚数 / 1注文
        "MaximumNumberOfSheetsPerOrder": "Maximum Number Of Sheets Per Order",
        // 販売チケット枚数
        "NumberOfTicketsSold": "Number Of Tickets Sold",
        // 1枚当たりのチケット秒数
        "TicketSecondsPerSheet": "Ticket Seconds Per Sheet",
        // チケット利用区分
        "TicketUsageClassification": "Ticket Usage Classification",
        // イベントタイプ
        "EventType": "Event Type",
        // 基本情報
        "BasicInformation": "Basic Information",
        // イベント設定
        "EventSetting": "Event Setting",
        // なし
        "None": "None",
        // バーチャルモデルプレビュー
        "VirtualModelPreview": "Virtual Model Preview",
        // 販売情報
        "SalesInformation": "Sales Information",
        // 円
        "Yen": "Yen",
        // 枚
        "Sheets": "Sheets",
        // 戻る
        "Back": "Back",
        // 次へ
        "Next": "Next",
        // 確認
        "Confirm": "Confirm",
        // アドレス
        "Address": "Address",
        // 一般配信用URL
        "GeneralDeliveryUrl": "General Delivery Url",
        // OBS配信用ドメイン
        "OBSDeliveryDomain": "OBS Delivery Domain",
        // OBS配信用キー
        "OBSDeliveryKey": "OBS Delivery Key",
        // 販売リンク
        "SalesLink": "Sales Link",
        // 詳細
        "Details": "Details",
        // 握手会
        "HandshakeMeeting": "Handshake Meeting",
        // 撮影会
        "PhotoSession": "Photo Session",
        // リアルタイム写真
        "RealTimePhoto": "Real Time Photo",
        // 事前準備の写真
        "PhotoOfPreparation": "Photo Of Preparation",
        // 両方選択
        "BothSelected": "Both Selected",
        // 成功
        "Success": "Success",
        // 成功しました！
        "Successed": "Successed!",
        // エラー
        "Error": "Error",
        // 予期できないエラーが発生しました。
        "UnexpectedErrorOccurred": "Unexpected Error Occurred.",
        // 本当に削除しますか？
        "DoYouReallyWantToDelete": "Do You Really Want To Delete?",
        // アラート
        "Alert": "Alert",
        // はい
        "Yes": "Yes",
        // いいえ
        "No": "No",
        // 開催前
        "BeforeTheEvent": "Before The Event",
        // 開催中
        "DuringTheEvent": "During The Event",
        // 開催終了
        "AfterTheEvent": "After The Event",
        // コピーしました
        "Copied": "Copied!",
        // コピー失敗しました
        "CopyFailed": "Copy Failed!",
        // シリアルまとめ売り
        "SerialSummarySales": "Serial Summary Sales",
        // シリアルばら売り
        "SerialBulkSales": "Serial Bulk Sales",
        // 削除
        "Delete": "Delete",
        // ファン管理
        "FanManagement": "Fan Management",
        // アンケート参照
        "QuestionnaireReference": "Questionnaire Reference",
        // 録画管理
        "RecordingManagement": "Recording Management",
        // スマホで配信する
        "DeliverOnSmartphone": "Deliver On Smartphone",
        "EventManagement": "Event Management",
        "ArtistName": "Artist Name",
        "EventName": "Event Name",
        "StartTime": "Start Time",
        "EndTime": "End Time",
        "AuthenticationCode": "Authentication Code",
        "Search": "Search",
        "Add": "Add",
        "EventStatus": "Event Status",
        "Title": "Title",
        "Organization": "Organization",
        "EventType": "Event Type",
        "1on1": "1on1",
        "Virtual": "Virtual",
        "TwoShot": "Two Shot",
        "LiveStreaming": "Live Streaming",
        "Standard": "Standard",
        "LowLatency": "Low Latency",
        "Sign": "Sign",
        "OrderType": "Order Type",
        "SalesOrder": "Sales Order",
        "AdmissionOrder": "Admission Order",
        "Artist": "Artist",
        "EventTime": "Event Time",
        "TicketQuantity": "Ticket Quantity",
        "SalesDestination": "Sales Destination",
        "Actions": "Actions",
        "Edit": "Edit",
        "Total": "Total",
        "SelectEventType": "Select Event Type",
        "SortByEntryTime": "Sort By Entry Time",
        "ArrangedBySpecifiedTime": "Arranged By Specified Time",
    },
    utils: {
        // {label}は必要項目です
        "Required": "{label} is required.",
        // {label}が一致しません
        "NotMatch": "{label} does not match.",
        // {label}の日時が間違っています
        "InvalidDateTime": "{label} is invalid.",
        // {label}は{min}文字以上です
        "MinLength": "{label} is at least {min} characters.",
        // {label}は${min}文字以内です
        "MaxLength": "{label} is within {min} characters.",
    },
    Cropper: {
        // 画像選択
        "ImageSelection": "Image Selection",
        // 画像編集
        "ImageEditing": "Image Editing",
        // キャンセル
        "Cancel": "Cancel",
        // 確認
        "Confirm": "Confirm",
    },
    ArtistsList: {
        // アーティスト管理
        "ArtistManagement": "Artist Management",
        // 組織名
        "OrganizationName": "Organization Name",
        // 芸名
        "StageName": "Stage Name",
        // 検索
        "Search": "Search",
        // 追加
        "Add": "Add",
        // アバター
        "Avatar": "Avatar",
        // アカウント
        "Account": "Account",
        // 操作
        "Actions": "Actions",
        // 編集
        "Edit": "Edit",
        // 削除
        "Delete": "Delete",
        // 合計
        "Total": "Total",
        // 記述
        "Description": "Description",
        // パスワード
        "Password": "Password",
        // 再確認
        "Reconfirm": "Reconfirm",
        // キャンセル
        "Cancel": "Cancel",
        // 確認
        "Confirm": "Confirm",
        // 組織
        "Organization": "Organization",
        // パスワードは5文字以上
        "PasswordIsAtLeast5Characters": "Password is at least 5 characters.",
        // 成功
        "Success": "Success",
        // 成功しました！
        "Successed": "Successed!",
        // エラー
        "Error": "Error",
        // 予期できないエラーが発生しました。
        "UnexpectedErrorOccurred": "Unexpected Error Occurred.",
        // 本当に削除しますか？
        "DoYouReallyWantToDelete": "Do You Really Want To Delete?",
        // アラート
        "Alert": "Alert",
        // はい
        "Yes": "Yes",
        // いいえ
        "No": "No",
    },
    BanList: {
        // ブラックリスト
        "Blacklist": "Black List",
        // 開始日付
        "StartDate": "Start Date",
        // コード/メールの検索
        "SearchCodeEmail": "Search Code/Email",
        // 検索
        "Search": "Search",
        // 時間
        "Time": "Time",
        // BANの時間
        "BanTime": "Ban Time",
        // 開始時間
        "StartTime": "Start Time",
        // 終了時間
        "EndTime": "End Time",
        // イベント
        "Event": "Event",
        // ニックネーム
        "Nickname": "Nickname",
        // 認証コード
        "AuthenticationCode": "Authentication Code",
        // メール
        "Email": "Email",
        // はい
        "Yes": "Yes",
        // いいえ
        "No": "No",
        // BANされた原因
        "BanReason": "Ban Reason",
        // 合計
        "Total": "Total",
    },
    FanList: {
        // イベント管理
        "EventManagement": "Event Management",
        // {eventTitle}のファン管理
        "FanManagementOfEventTitle": "Fan Management of {eventTitle}",
        // ネーム/コード/メール
        "NameCodeEmail": "Name/Code/Email",
        // 検索
        "Search": "Search",
        // ポイントインポート
        "PointImport": "Point Import",
        // ポイントエクスポート
        "PointExport": "Point Export",
        // エクスポート
        "Export": "Export",
        // 一括追加
        "BulkAddition": "Bulk Addition",
        // アバター
        "Avatar": "Avatar",
        // メール
        "Email": "Email",
        // はい
        "Yes": "Yes",
        // いいえ
        "No": "No",
        // 認証コード
        "AuthenticationCode": "Authentication Code",
        // 時間帯
        "Timezone": "Timezone",
        // 時間
        "Time": "Time",
        // 秒
        "Seconds": "Seconds",
        // ポイント
        "Point": "Point",
        // 操作
        "Actions": "Actions",
        // 編集
        "Edit": "Edit",
        // 追加
        "Add": "Add",
        // 合計
        "Total": "Total",
        // ニックネーム
        "Nickname": "Nickname",
        // メールアドレス
        "EmailAddress": "Email Address",
        // キャンセル
        "Cancel": "Cancel",
        // 確認
        "Confirm": "Confirm",
        // 一括追加
        "BulkAddition": "Bulk Addition",
        // 作成数
        "CreationNumber": "Creation Number",
        // 撮った写真
        "TakenPhotos": "Taken Photos",
        // ツーショット
        "TwoShot": "Two Shot",
        // エラー
        "Error": "Error",
        // 予期できないエラーが発生しました。
        "UnexpectedErrorOccurred": "Unexpected Error Occurred.",
        // 権利枚数
        "NumberOfRights": "Number Of Rights",
        // 成功
        "Success": "Success",
        // 成功しました！
        "Successed": "Successed!",
        // 本当にBANしますか？
        "DoYouReallyWantToBan": "Do You Really Want To Ban?",
        // アラート
        "Alert": "Alert",
        // コピーしました
        "Copied": "Copied",
        // コピー失敗しました
        "CopyFailed": "Copy Failed",
        // 写真
        "Photo": "Photo",
        // 振替
        "Transfer": "Transfer",
        // サイン
        "Sign": "Sign",
    }
};