import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/common.css";
import "primevue/resources/primevue.min.css";
import "./assets/css/theme.css";
import "primeicons/primeicons.css";
import "cropperjs/dist/cropper.css";
import "primeflex/primeflex.css";
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import ConfirmationService from "primevue/confirmationservice";
import Tooltip from 'primevue/tooltip';
import Popper from "vue3-popper";
import i18n from "./lang";
import { PrimeVueLocale } from "@/utils/utils"

const locale = i18n.global.locale?.value || "ja"
const app = createApp(App);
app.component("Popper", Popper);
app
  .use(store)
  .use(router)
  .use(i18n)
  .use(PrimeVue, {
    locale: PrimeVueLocale(locale),
  })
  .use(ToastService)
  .use(ConfirmationService)
  .use(VueSidebarMenu).directive('tooltip', Tooltip)
  .mount("#app");
app.config.globalProperties.$getUrlTime = () => {
  return `t=${new Date().getTime()}`;
};
window["$getUrlTime"] = app.config.globalProperties.$getUrlTime
console.log = (function (log) {
  return process.env.VUE_APP_NO_CONSOLE == "1" ? function () { } : log;
})(console.log);

app.config.globalProperties.$i18n = i18n;
window.$i18n = i18n;

