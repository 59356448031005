const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
export default function (api) {
  return {
    login (data) {
      return api.post(apiBaseUrl + "/login", data, {
        shouldJWT: false,
        noLoading: false,
      });
    },
    sysLogin (data) {
      return api.post(apiBaseUrl + "/sys_login", data, {
        shouldJWT: false,
        noLoading: false,
      });
    },
    getAccountMe () {
      return api.get(apiBaseUrl + "/account/me", {
        shouldJWT: true,
        noLoading: false,
      });
    },
    settingMe (data) {
      return api.put(apiBaseUrl + "/account/me", data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    reflushAccount (data) {
      return api.post(apiBaseUrl + "/refresh", data, {
        shouldJWT: false,
        noLoading: false,
        isReflush: true,
      });
    },
    getMenu () {
      return api.get(apiBaseUrl + "/menus", {
        shouldJWT: true,
        noLoading: false,
      });
    },
    // 主播相关
    getLiverList (data) {
      return api.get(apiBaseUrl + "/livers", {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    creatLivers (data) {
      return api.post(apiBaseUrl + "/livers", data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    getLivers (id) {
      return api.get(apiBaseUrl + "/livers/" + id, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    editLivers (id, data) {
      return api.put(apiBaseUrl + "/livers/" + id, data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    deleteLivers (id) {
      return api.delete(apiBaseUrl + "/livers/" + id, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    // 活动相关
    getEventList (data) {
      return api.get(apiBaseUrl + "/events", {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    creatEvents (data) {
      return api.post(apiBaseUrl + "/events", data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    getEvents (id) {
      return api.get(apiBaseUrl + "/events/" + id, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    editEvents (id, data) {
      return api.put(apiBaseUrl + "/events/" + id, data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    deleteEvents (id) {
      return api.delete(apiBaseUrl + "/events/" + id, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    getEventsConfig () {
      return api.get(apiBaseUrl + "/events/config", {
        shouldJWT: true,
        noLoading: false,
      });
    },
    getPushUrl (id) {
      return api.get(apiBaseUrl + "/events/" + id + "/pushUrl", {
        shouldJWT: true,
        noLoading: false,
      });
    },
    getVideoList (id) {
      return api.get(apiBaseUrl + "/events/" + id + "/videos", {
        shouldJWT: true,
        noLoading: false,
      });
    },
    getInfoLang () {
      return api.get(apiBaseUrl + "/info/lang", {
        shouldJWT: true,
        noLoading: false,
      });
    },
    // 监视相关
    getLivingList (data) {
      return api.get(apiBaseUrl + "/events/living", {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    getLivingDetail (id) {
      return api.get(apiBaseUrl + "/events/living/" + id, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    getWaitingList (id) {
      return api.get(apiBaseUrl + `/events/living/${id}/waiting`, {
        shouldJWT: true,
        noLoading: true,
      });
    },
    sendNotice (id, data) {
      return api.post(apiBaseUrl + `/events/living/${id}/notice`, data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    ban (id, data) {
      return api.post(apiBaseUrl + `/events/living/${id}/ban`, data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    addMonitorLog (id, data) {
      return api.post(apiBaseUrl + `/events/living/${id}/addMonitorLog`, data, {
        shouldJWT: true,
        noLoading: false,
      });
    },

    // 粉丝相关
    getFanList (data) {
      return api.get(apiBaseUrl + "/fans", {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    creatFans (data) {
      return api.post(apiBaseUrl + "/fans", data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    getFans (id) {
      return api.get(apiBaseUrl + "/fans/" + id, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    editFans (id, data) {
      return api.put(apiBaseUrl + "/fans/" + id, data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    deleteFans (id) {
      return api.delete(apiBaseUrl + "/fans/" + id, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    getBanlist (data) {
      return api.get(apiBaseUrl + "/fans/banlist", {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    fanBatch (data) {
      return api.post(apiBaseUrl + "/fans/batch", data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    fanDwonload (data) {
      return api.post(apiBaseUrl + "/fans/download", data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    fanPointDownload (data) {
      return api.post(apiBaseUrl + "/fans/point/download", data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    fanPointImportant (data) {
      return api.post(apiBaseUrl + "/fans/point/import", data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    fanPhoto (id) {
      return api.get(apiBaseUrl + "/fans/" + id + "/photos", {
        shouldJWT: true,
        noLoading: false,
      });
    },
    postFansTransfer (data) {
      return api.post(apiBaseUrl + "/fans/transfer", data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    // 管理员
    getAdminList (data) {
      return api.get(apiBaseUrl + "/admins", {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    creatAdmins (data) {
      return api.post(apiBaseUrl + "/admins", data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    getAdmins (id) {
      return api.get(apiBaseUrl + "/admins/" + id, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    editAdmins (id, data) {
      return api.put(apiBaseUrl + "/admins/" + id, data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    deleteAdmins (id) {
      return api.delete(apiBaseUrl + "/admins/" + id, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    proxyLogin (id) {
      return api.put(
        apiBaseUrl + `/admins/${id}/proxyLogin`,
        {},
        {
          shouldJWT: true,
          noLoading: false,
        }
      );
    },
    // 权限列表
    getRoleList (data) {
      return api.get(apiBaseUrl + "/admins/roles", {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    // 组织
    organizations (data) {
      return api.get(apiBaseUrl + "/organizations", {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    creatOrganizations (data) {
      return api.post(apiBaseUrl + "/organizations", data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    getOrganizations (id) {
      return api.get(apiBaseUrl + "/organizations/" + id, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    editOrganizations (id, data) {
      return api.put(apiBaseUrl + "/organizations/" + id, data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    deleteOrganizations (id) {
      return api.delete(apiBaseUrl + "/organizations/" + id, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    // 问卷
    getEnquetList (id, data) {
      return api.get(apiBaseUrl + `/events/${id}/enquete`, {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    getEnquet (eventId, enqueteId) {
      return api.get(apiBaseUrl + `/events/${eventId}/enquete/${enqueteId}`, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    sendEnquet (eventId, enqueteId, data) {
      return api.put(
        apiBaseUrl + `/events/${eventId}/enquete/${enqueteId}`,
        data,
        {
          shouldJWT: true,
          noLoading: false,
        }
      );
    },
    sendEnquetProcess (eventId, enqueteId, data) {
      return api.put(
        apiBaseUrl + `/events/${eventId}/enquete/${enqueteId}/process`,
        data,
        {
          shouldJWT: true,
          noLoading: false,
        }
      );
    },
    sendEnquetRefund (eventId, enqueteId, data) {
      return api.post(
        apiBaseUrl + `/events/${eventId}/enquete/${enqueteId}/refund`,
        data,
        {
          shouldJWT: true,
          noLoading: false,
        }
      );
    },

    // office
    offices (data) {
      return api.get(apiBaseUrl + "/offices", {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    getOfficeDetail (id) {
      return api.get(apiBaseUrl + `/offices/${id}`, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    getOfficeTheme (id) {
      return api.get(apiBaseUrl + `/theme/office/${id}`, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    putOfficeTheme (id, data) {
      return api.put(apiBaseUrl + `/theme/office/${id}`, data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    getOfficeDefaultTheme () {
      return api.get(apiBaseUrl + `/theme/default`, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    putOfficeDefaultTheme (data) {
      return api.put(apiBaseUrl + `/theme/default`, data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    // 合照场景
    getPhotoScenes (data) {
      return api.get(apiBaseUrl + "/photo_scenes", {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    getScenePose (data) {
      return api.get(apiBaseUrl + "/photo_scenes/pose", {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    getPhotoSceneDetail (id, data) {
      return api.get(apiBaseUrl + `/photo_scenes/${id}`, {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    putPhotoScene (id, data, organizationId) {
      return api.put(
        apiBaseUrl + `/photo_scenes/${id}?organizationId=${organizationId}`,
        data,
        {
          shouldJWT: true,
          noLoading: false,
        }
      );
    },
    deletePhotoSceneDetail (id, data) {
      return api.delete(apiBaseUrl + `/photo_scenes/${id}`, {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    postPhotoScene (data, organizationId) {
      return api.post(
        apiBaseUrl + `/photo_scenes?organizationId=${organizationId}`,
        data,
        {
          shouldJWT: true,
          noLoading: false,
        }
      );
    },
    // dashboard
    getDashboard () {
      return api.get(apiBaseUrl + "/dashboard", {
        shouldJWT: true,
        noLoading: false,
      });
    },
    getDashboardLiverRank (data) {
      return api.get(apiBaseUrl + "/dashboard/liverRank", {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    // 签名框
    getPhotoFrame (data) {
      return api.get(apiBaseUrl + "/photo_frame", {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    getPhotoFrameDetail (id, data) {
      return api.get(apiBaseUrl + "/photo_frame/" + id, {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    putPhotoFrameDetail (id, data, organizationId) {
      return api.put(
        apiBaseUrl + `/photo_frame/${id}?organizationId=${organizationId}`,
        data,
        {
          shouldJWT: true,
          noLoading: false,
        }
      );
    },
    deletePhotoFrameDetail (id, data) {
      return api.delete(
        apiBaseUrl + `/photo_frame/${id}`,
        {
          params: data,
          shouldJWT: true,
          noLoading: false,
        }
      );
    },
    postPhotoFrameDetail (data, organizationId) {
      return api.post(
        apiBaseUrl + `/photo_frame?organizationId=${organizationId}`,
        data,
        {
          shouldJWT: true,
          noLoading: false,
        }
      );
    },
    // 礼物
    getGiftGroupList (data) {
      return api.get(apiBaseUrl + "/gift_group", {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    getGiftGroupDetail (id) {
      return api.get(apiBaseUrl + "/gift_group/" + id, {
        params: data,
        shouldJWT: true,
        noLoading: false,
      });
    },
    addGiftGroup (data) {
      return api.post(apiBaseUrl + `/gift_group`, data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    editGiftGroup (id, data) {
      return api.put(apiBaseUrl + `/gift_group/${id}`, data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    deleteGiftGroup (id) {
      return api.delete(apiBaseUrl + "/gift_group/" + id, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    addGift (groupId, data) {
      return api.post(apiBaseUrl + `/gift_group/${groupId}/gift`, data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    editGift (id, groupId, data) {
      return api.put(apiBaseUrl + `/gift_group/${groupId}/gift/${id}`, data, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    deleteGift (id, groupId) {
      return api.delete(apiBaseUrl + `/gift_group/${groupId}/gift/${id}`, {
        shouldJWT: true,
        noLoading: false,
      });
    },
    //模型管理
    getVirtualModelList (data) {
      return api.get(apiBaseUrl + `/virtual`, {
        params: data,
        shouldJWT: true,
        noLoading: false,
      })
    },
    getVirtualModelDetail (id) {
      return api.get(apiBaseUrl + `/virtual/${id}`, {
        shouldJWT: true,
        noLoading: false,
      })
    },
    addVirtualModel (data) {
      return api.post(apiBaseUrl + `/virtual`, data, {
        shouldJWT: true,
        noLoading: false,
      })
    },
    editVirtualModel (id, data) {
      return api.put(apiBaseUrl + `/virtual/${id}`, data, {
        shouldJWT: true,
        noLoading: false,
      })
    },
    deleteVirtualModel (id) {
      return api.delete(apiBaseUrl + `/virtual/${id}`, {
        shouldJWT: true,
        noLoading: false,
      })
    },
    virtualUploadinfo () {
      return api.get(apiBaseUrl + `/virtual/uploadinfo`, {
        shouldJWT: true,
        noLoading: false,
      })
    },
    // 时间
    getServerTime () {
      return api.get(apiBaseUrl + "/sync/time", {
        shouldJWT: true,
        noLoading: true,
      });
    },
    // 其他
    downloadCos (url) {
      return api.get(url, {
        responseType: 'blob'
      });
    }
  };
}
