import { createAPI, source, isCancel } from "./create-api";
import auth from "./module/auth";
import { showLoading, hideLoading, getRefreshToken, getLang } from "../utils/utils";
import store from "../store";
import router from "@/router";

let refreshTokenPromise;
const logout = () => {
  console.warn("auth error go to login");
  store.dispatch("setToken", "");
  switch (store.getters.adminType) {
    case "0":
      store.dispatch("setAdminToken", "");
      localStorage.removeItem("adminToken");
      localStorage.removeItem("adminRefreshJwt");
      router.replace({ name: "Login" });
      break;
    case "1":
      store.dispatch("setPlatformToken", "");
      localStorage.removeItem("platformToken");
      localStorage.removeItem("platformRefreshJwt");
      router.replace({ name: "PlatformLogin" });
      break;
    default:
      break;
  }
  store.dispatch("setMenuData", null);
};
const api = createAPI();
//请求拦截器
api.interceptors.request.use(
  (config) => {
    // const data = config.data || {};
    let shouldJWT = config.shouldJWT;
    if (shouldJWT) {
      if (store.getters.token) {
        config.headers["Authorization"] = `Bearer ${store.getters.token}`;
      } else {
        logout();
        config.cancelToken = source.token;
      }
    }
    config.headers['Accept-Language'] = getLang()
    const noLoading = config.noLoading;
    console.log(config);
    if (!noLoading) {
      showLoading();
    }
    return config;
  },
  (error) => {
    const config = error.response?.config || {};
    const noLoading = config.noLoading;
    if (!noLoading) {
      hideLoading();
    }
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  (response) => {
    const config = response.config;
    const noLoading = config.noLoading;
    if (!noLoading) {
      hideLoading();
    }
    return response;
  },
  (error) => {
    const config = error.response?.config || {};
    const noLoading = config.noLoading;
    if (!noLoading) {
      hideLoading();
    }
    if (isCancel(error)) {
      console.warn("Request canceled", error.message);
    }
    const errorCode = error.response?.status;
    console.log(errorCode);
    if (errorCode === 401 && !error.config._retry && !error.config.isReflush) {
      // 如果服务器返回认证错误&&不是第二次重试&&不是刷新接口
      if (!refreshTokenPromise) {
        // 如果没有创建刷新token的promise的情况下创建一个刷新token的Promise
        error.config._retry = true;
        refreshTokenPromise = getRefreshToken().then(() => {
          refreshTokenPromise = null;
        });
      }
      // 返回一个刷新token的promise
      return refreshTokenPromise
        .then(() => {
          // 当刷新token的promise返回成功时重新尝试调用接口
          return api.request(error.config);
        })
        .catch(() => {
          // 刷新失败则退出并返回错误
          logout();
          return Promise.reject(error);
        });
    }

    return Promise.reject(error);
  }
);
export const authApi = auth(api);
